const ROUND1 = 'Rnd 1 - HPR Full Course'
const ROUND2 = 'Rnd 2 - PPIR'
const ROUND3 = 'Rnd 3 - HPR West Course'
const ROUND4 = 'Rnd 4 - HPR Full'
const ROUND5 = 'Rnd 5 - PMP'
const ROUND6 = 'Rnd 6 - HPR North'
const ROUND7 = 'Rnd 7 - HPR Full'
const MISSED = 'Missed - Both bikes in shop'
export const NovGTU = [{
  round: ROUND1,
  place: '13th',
  time: { 
    actual: '2:07.501',
  },
  note: 'Second wave; Out of gas last lap, ran bike across finish'
}, {
  round: ROUND2,
  time: {
    actual: '1:08.501',
    place: 3,
  },
  place: '3rd',
}, {
  round: ROUND3,
  time: {
    actual: '1:32.658',
    place: 4,
  },
  place: '4th',
}, {
  round: ROUND4,
  time: { 
    actual: '1:58.332',
    place: 5,
  },
  place: '6th',
}, {
  round: ROUND5,
  note: MISSED
}, {
  round: ROUND6,
  time: {
    actual: '1:19.429',
    place: 5,
  },
  place: '5th',
}, {
  round: ROUND7,
  time: {
    actual: '1:56.685',
    place: 5,
  },
  place: '5th',
}]

export const LW2X = [{
  round: ROUND1,
  note: 'Did not participate',
}, {
  round: ROUND2,
  time: {
    actual: '1:06.141',
  },
  place: '4th',
}, {
  round: ROUND3,
  time: {
    actual: '1:34.969',
    place: 2,
  },
  place: '2nd',
}, {
  round: ROUND4,
  note: 'No 2x Round 4 - Endurance round',
}, {
  round: ROUND5,
  note: MISSED
}, {
  round: ROUND6,
  time: {
    actual: '1:23.294',
    place: 3,
  },
  place: '13th',
  note: 'Wreck in turn 10'
}, {
  round: ROUND7,
  time: {
    actual: '2:02.547',
    place: 5,
  },
  place: '4th',
}]

export const Thunder = [{
  round: ROUND1,
  time: {
    actual: '2:04.716',
  },
  place: '9th',
}, {
  round: ROUND2,
  time: {
    actual: '1:04.437',
  },
  place: '6th',
}, {
  round: ROUND3,
  time: {
    actual: '1:36.526',
  },
  place: '8th',
}, {
  round: ROUND4,
  time: {
    actual: '2:00.071',
  },
  place: '7th',
}, {
  round: ROUND5,
  note: MISSED
}, {
  round: ROUND6,
  time: {
    actual: '1:23.480',
  },
  place: '6th',
}, {
  round: ROUND7,
  time: {
    actual: '1:58.889',
  },
  place: '3rd',
}]

export const STGTU = [{
  round: ROUND1,
  time: {
    actual: '2:03.412',
  },
  place: '3nd',
}, {
  round: ROUND2,
  time: {
    actual: '1:05.042',
  },
  place: '3rd',
}, {
  round: ROUND3,
  time: {
    actual: '1:33.472',
  },
  place: '3nd',
}, {
  round: ROUND4,
  time: {
    actual: '2:00.396',
  },
  place: '5nd',
}, {
  round: ROUND5,
  note: MISSED
}, {
  round: ROUND6,
  time: {
    actual: '1:23.479',
  },
  place: '3th',
}, {
  round: ROUND7,
  time: {
    actual: '2:01.215',
  },
  place: '4rd',
}]