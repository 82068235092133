import { Fragment } from 'react'
import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import Typography from '../../../components/Typography'
const CHAMP_IMG={
  width: '200px',
  height: '285px'
}
const SubSectionTemplate = ({image, title, contents, Override}) => (
  <Box>
    <Flex justifyContent='center'>
      <Typography variant='h2'>{title}</Typography>
    </Flex>
    {Override ? (
      <Box pl={16}>
        <Override/>
      </Box>
    ) : (
      <Flex pl={16}>
        {image ? (
          <Box pr={2}>
            <img 
            src={image} 
            alt="todo" 
            style={CHAMP_IMG}
            />
          </Box>
        ) : null}
        <Box>
          {contents.map(({title, details}, index) => (
            <Fragment key={`${title}-${index}`}>
              {title ? <Typography variant="h4">{title}</Typography> : null}
              <Typography pb={2}>{details}</Typography>
            </Fragment>
          ))}
        </Box>
      </Flex>
    )}
  </Box>
)

export default SubSectionTemplate