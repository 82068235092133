import Typography from '../../../../components/Typography'
import ChampionshipTable from '../../components/ChampionshipTable'
import { amateurGTU, amateurGTO } from './2024'
import { LW2X, NovGTU, STGTU, Thunder } from './2023'
import SubSectionTemplate from '../../components/SubSectionTemplate'
import Flex from '../../../../components/Flex'
import AmGTO2024 from '../../../../assets/championships/2024-AmGTO.jpg'
import AmGTU2024 from '../../../../assets/championships/2024-AmGTU.jpg'
import LW2X2023 from '../../../../assets/championships/2023-LW2X.jpg'
import Thun2023 from '../../../../assets/championships/2023-Thun.jpg'
import STGTU2023 from '../../../../assets/championships/2023-STGTU.jpg'
import NovGTU2023 from '../../../../assets/championships/2023-NovGTU.jpg'

const CHAMP_IMG={
  width: '200px',
  height: '285px'
}

const Championships = () => (    
  <>
    <Typography variant="h4">2024 MRA Championships</Typography>
    <Flex>
      <img 
        src={AmGTO2024} 
        alt="AmGTO2024" 
        style={CHAMP_IMG}
        />
      <ChampionshipTable title="Amateur GTO - 3rd Overall" data={amateurGTO}/>
    </Flex>
    <Flex>
      <img 
        src={AmGTU2024} 
        alt="AmGTU2024" 
        style={CHAMP_IMG}
        />
      <ChampionshipTable title="Amateur GTU - 4th Overall" data={amateurGTU}/>
    </Flex>
    <Typography variant="h4">2023 MRA Championships</Typography>
    <Flex pb={4}>
      <img 
        src={STGTU2023} 
        alt="2023 SuperTwins GTU Championship" 
        style={CHAMP_IMG}
        />
      <ChampionshipTable title="SuperTwins GTU - 3rd Overall" data={STGTU}/>
    </Flex>
    <Flex py={4}>
      <img 
        src={NovGTU2023} 
        alt="2023 Novice GTU Championship" 
        style={CHAMP_IMG}
        />
      <ChampionshipTable title="Novice GTU - 5th Overall" data={NovGTU}/>
    </Flex>
    <Flex py={4}>
      <img 
        src={Thun2023} 
        alt="2023 Thunderbike Championship" 
        style={CHAMP_IMG}
        />
      <ChampionshipTable title="Thunderbike - 5th Overall" data={Thunder}/>
    </Flex>
    <Flex pt={4}>
      <img 
        src={LW2X2023} 
        alt="2023 Lightweight 2x Championship" 
        style={CHAMP_IMG}
        />
      <ChampionshipTable title="Lightweight 2x - 5th Overall" data={LW2X}/>
    </Flex>
  </>
)
const Highlights = () => (
  <SubSectionTemplate
    title="Highlights"
    Override={Championships}
    />
)

export default Highlights