import SubSectionTemplate from "../components/SubSectionTemplate"
import SethMcClaine from '../../../assets/bio/2024-SethMcClaine.jpg'

const points = [
  {
    details: "Originally from Virginia, I moved out to Colorado in 2010; within a year I bought my first bike and had my endorsement. Though I have been riding motorcycles for nearly 15 years, I only claim the last 3-4 years, anything prior to that I was doing it wrong! In the past two years I have joined my first motorsport racing club, The Motorcycle Racing Association. My novice year I was able to finish top 5 in the Novice GTU class progressing me to an expert plate. My second year I also managed to finish top 5 (3rd and 4th) in Amateur Classes. With my first two years being extremely productive I look forward to the 2025 season! Outside of Racing I've been married since 2017 to my wife who not only supports and encourages my racing career but has joined the community by assisting in motorsports photography (check out McClaineMedia.com). I also have a three-year-old son and am expecting another child within the next year. My career as a software engineer has allowed me to pursue the hobby of motorcycle racing. I have grown up competing in various sports such as soccer, ultimate frisbee, snowboarding, mountain biking, cycling and others. Beyond racing itself I enjoy mentoring others in the things I enjoy. On race weekends you can find me socializing with friends and competitors, and trying to help out those new to the sport; from giving advice to on track coaching."
  }, {
    details: "Want to hear more about my career specifically!? Read my Narrative!"
  }
]

const Bio = () => (
  <SubSectionTemplate
    title="Bio"
    image={SethMcClaine}
    contents={points}
    />
)

export default Bio
